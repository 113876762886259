/* Google Font */

/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f5f5f5;
  padding: 20px;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
}

.header img {
  height: 70px;
}

h1 {
  color: #555;
  font-weight: 700;
}

p, ol {
  line-height: 1.6;
  color: #666;
}

a {
  color: #0066CC;
}

/* Button Styles */
.accordion {
  background-color: #f8f9fa;
  color: #333;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: background-color 0.4s ease;
  font-size: 16px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
}

.accordion:hover, .active {
  background-color: #e9ecef;
}

.panel {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  overflow: hidden;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.15);
}

.tuto{
  width: 30%;
}

.panel-instruction {
  font-style: italic;
  color: #666;
  margin-bottom: 15px;
}

.footer {
  text-align: center;
  margin-top: 50px;
  font-size: 0.8em;
  color: #888;
}

input {
  max-width: 400px;
  min-width: 120px;
}

#collaboratorSelect, #dateSelect {
  max-width: 300px;
  min-width: 120px;
}
